<template>
  <ion-footer>
    <ion-toolbar class="mx-0">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-row>
              <ion-text>{{ isCreateTopUpPayment ? $t('top_up_credits') : $t('total_payment') }}</ion-text>
            </ion-row>
            <ion-row>
              <ion-label class="f-price">{{ priceFormatter(currencySymbol, totalPaidAmount) }}</ion-label>
            </ion-row>
          </ion-col>
          <ion-col
            v-if="
              (!isDigitalPayment && !isCreateTopUpPayment) ||
              (!isFullyPaidCustBalance &&
                paymentType === INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT &&
                !isCreateTopUpPayment)
            "
            size="auto"
            @click="$emit('editPayment')"
            class="ion-justify-content-center"
          >
            <ion-icon color="primary" class="pr-1 pt-2 i-pencil" :icon="pencilOutline"></ion-icon>
            <ion-text class="fw-500 fs-2">{{ $t('edit') }}</ion-text>
          </ion-col>
        </ion-row>
        <div class="d-flex align-center justify-center mt-5">
          <ion-button
            color="primary"
            fill="solid"
            @click="$emit('handlePayInvoices')"
            :disabled="
              !paymentType ||
              (paymentType === INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT &&
                !isFullyPaidCustBalance &&
                totalPaidAmount !== 0)
            "
          >
            <ion-icon
              v-show="isDigitalPayment || isFullyPaidCustBalance"
              color="light"
              :icon="shieldCheckmarkOutline"
            ></ion-icon>
            <ion-label class="ml-1">{{
              isDigitalPayment ||
              isFullyPaidCustBalance ||
              (!isFullyPaidCustBalance &&
                paymentType === INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT &&
                totalPaidAmount === 0)
                ? $t('pay')
                : $t('next')
            }}</ion-label>
          </ion-button>
        </div>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</template>
<script>
import { INVOICE_PAYMENT_TYPE } from '@/modules/shared/constants';
import { priceFormatter } from '@/utils/';
import { pencilOutline, shieldCheckmarkOutline } from 'ionicons/icons';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'CompBottomContent',
  emits: ['handlePayInvoices', 'editPayment'],
  props: {
    totalPaidAmount: {
      type: Number,
      default: 0
    },
    currencySymbol: {
      type: String,
      default: ''
    },
    isDigitalPayment: {
      type: Boolean,
      default: false
    },
    paymentType: {
      type: Number,
      default: 0
    },
    isFullyPaidCustBalance: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore();
    const isCreateTopUpPayment = computed(
      () => store.getters[`sale/payment/paramsTopUpCredit`]?.totalPaidAmount > 0 ?? false
    );
    return {
      isCreateTopUpPayment,
      shieldCheckmarkOutline,
      pencilOutline,
      INVOICE_PAYMENT_TYPE,
      priceFormatter
    };
  }
});
</script>
<style lang="scss" scoped>
ion-button {
  --border-radius: 10px;
  width: 95%;
  height: 44px;
}
ion-label {
  text-transform: capitalize;
}
.f-price {
  font-weight: bold;
  font-size: 22px;
  margin-top: 0.7rem;
}
.i-pencil {
  font-size: 1rem;
}
ion-card {
  margin-bottom: 1px;
  border: none;
}
</style>
