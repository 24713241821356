<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-label class="fw-600 fs-3">{{ $t('payment_summary') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true">
      <div class="w-100 bg-gray-modal h-100 pt-5 pb-5" v-if="!isCreateTopUpPayment">
        <div class="pb-5 d-flex align-center justify-center flex-column">
          <ion-icon
            class="f-icon"
            :class="paymentStatusSelected?.color"
            :icon="paymentStatusSelected?.icon"
          ></ion-icon>
          <ion-label class="fs-4 fw-600 mt-2 mb-2" :class="paymentStatusSelected?.color">
            {{ paymentStatusSelected?.label }}</ion-label
          >
        </div>
        <ion-grid class="my-2 box px-2 py-2">
          <ion-row class="mb-2 mx-1 ion-justify-content-between">
            <ion-label class="text-gray-700">{{ `Invoice(s) Amount` }}</ion-label>
            <ion-label style="float: right" class="fw-600 text-gray-700">{{
              priceFormat(invoiceAmount)
            }}</ion-label>
          </ion-row>
          <ion-row class="mb-2" v-for="(invoice, i) in invoicesData" :key="i">
            <ion-col push="0.3">
              <ion-label class="text-gray-700">{{ invoice.label }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-600 text-gray-700">{{ invoice.value }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row
            v-for="(item, idx) in dataSummary"
            :key="idx"
            class="mb-2"
            :class="{ 'border-bottom': [1, 2, 4].includes(idx) }"
          >
            <ion-col>
              <ion-label
                class="text-gray-700"
                :class="{
                  'text-primary-green-600': idx === 0,
                  'fw-700': idx == 5,
                  'fw-500': [1, 2, 0].includes(idx)
                }"
                >{{ item.label }}</ion-label
              >
            </ion-col>
            <ion-col size="auto">
              <ion-text
                class="fw-600 text-gray-700"
                :class="
                  idx === 5 ? `${paymentStatusSelected?.color}` : idx === 0 ? 'text-primary-green-600' : ''
                "
              >
                {{ item.value }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col> </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-700 text-gray-700">
                {{ priceFormat(totalPaidAmount) }}
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-button class="my-2" @click="$emit('backToInvoice')" expand="block" color="primary">
            <ion-label class="text-capitalize">
              {{ $t('back_to_invoice') }}
            </ion-label>
          </ion-button>
          <ion-button class="mb-4" @click="$emit('backToHome')" fill="outline" expand="block">
            <ion-label class="text-capitalize">
              {{ $t('back_to_home') }}
            </ion-label>
          </ion-button>
        </ion-grid>
      </div>
      <div class="w-100 bg-gray-modal h-100 pt-5 pb-5" v-else>
        <div class="pb-5 d-flex align-center justify-center flex-column">
          <ion-icon
            class="f-icon"
            :class="paymentStatusSelected?.color"
            :icon="paymentStatusSelected?.icon"
          ></ion-icon>
          <ion-label class="fs-4 fw-600 mt-2 mb-2" :class="paymentStatusSelected?.color">
            {{ paymentStatusSelected?.label }}</ion-label
          >
        </div>
        <ion-grid class="w-90 rounded-sm box shadow-sm px-3 pt-3 bg-white">
          <ion-row class="mb-1 bor-bot">
            <ion-col>
              <ion-label class="fw-500 text-gray-700">{{ $t('top_up_credits') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-500 text-gray-700">{{
                priceFormat(+topUpSummaryData.totalPaidAmount)
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="mb-1">
            <ion-col>
              <ion-label class="fw-500 text-gray-700">{{ $t('payment_method') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-500 text-gray-700">{{ paymentMethod }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="mb-1">
            <ion-col>
              <ion-label class="fw-500 text-gray-700">{{ $t('transaction_date') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-500 text-gray-700">{{
                formatDayDate(paymentSummaryTopUp.details?.payment_date)
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="bor-top">
            <ion-col>
              <ion-label class="fw-600 text-gray-700">{{ $t('total') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-text
                class="fw-500"
                :class="PAYMENT_HISTORY_LIST_TEXT_COLOR[paymentSummaryTopUp.details?.status]"
              >
                {{ PAYMENT_SUMMARY_STATUS_LABEL[paymentSummaryTopUp.details?.status] }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col> </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-700 text-gray-700">
                {{ priceFormat(+topUpSummaryData.totalPaidAmount) }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-button
              class="my-2"
              @click="
                topUpEntryPoint === TOP_UP_CREDIT_ENTRY.ACCOUNT_PAGE
                  ? $emit('backToAccount')
                  : $emit('backToInvoice')
              "
              expand="block"
              color="primary"
            >
              <ion-label class="text-capitalize">
                {{
                  topUpEntryPoint === TOP_UP_CREDIT_ENTRY.ACCOUNT_PAGE
                    ? $t('back_to_account')
                    : $t('back_to_invoice')
                }}
              </ion-label>
            </ion-button>
            <ion-button class="mb-4" @click="$emit('backToHome')" fill="outline" expand="block">
              <ion-label class="text-capitalize">
                {{ $t('back_to_home') }}
              </ion-label>
            </ion-button>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  INVOICE_PAYMENT_TYPE,
  PAYMENT_HISTORY_LIST_TEXT_COLOR,
  PAYMENT_SUMMARY_STATUS_LABEL,
  TOP_UP_CREDIT_ENTRY
} from '@/modules/shared/constants/';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils';
import { useBackButton } from '@ionic/vue';
import { alertCircleOutline, checkmarkCircleOutline, closeCircleOutline, timerOutline } from 'ionicons/icons';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ModalDigitalPaymentSummary',
  emits: ['backToInvoice', 'backToHome', 'backToAccount'],
  props: {
    paymentSummary: {
      type: Object,
      default: () => {}
    },
    currencySymbol: {
      type: String,
      default: 'S$'
    },
    invoices: {
      type: Array,
      default: () => []
    },
    appliedCredit: {
      type: Number,
      default: 0
    },
    totalPaidAmount: {
      type: Number,
      default: 0
    },
    grandTotal: {
      type: Number,
      default: 0
    },
    paymentType: {
      type: Number,
      default: INVOICE_PAYMENT_TYPE.CREDIT_CARD
    }
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { formatDayDate } = useDateFormatter();
    const isCreateTopUpPayment = computed(
      () => store.getters[`sale/payment/paramsTopUpCredit`]?.totalPaidAmount > 0 ?? false
    );
    const topUpSummaryData = computed(() => store.getters[`sale/payment/paramsTopUpCredit`]);
    const paymentSummaryTopUp = computed(() => store.getters[`sale/payment/paymentSummaryTopUp`]);
    const topUpEntryPoint = computed(() => store.getters[`sale/payment/topUpCreditEntry`]);
    const paymentStatusValue = ref(
      isCreateTopUpPayment.value
        ? paymentSummaryTopUp.value?.details?.status ?? 'approved'
        : props.paymentSummary?.details?.batchPayment?.status
    );
    const paymentStatus = [
      {
        label: t('payment_success'),
        icon: checkmarkCircleOutline,
        color: 'text-primary-green-600',
        btmLabel: t('paid').toUpperCase(),
        value: 'approved'
      },
      {
        label: t('payment_failed'),
        icon: closeCircleOutline,
        color: 'text-error-600',
        btmLabel: t('failed').toUpperCase(),
        value: 'rejected'
      },
      {
        label: t('payment_pending'),
        icon: alertCircleOutline,
        color: 'text-primary-orange-500',
        btmLabel: t('pending').toUpperCase(),
        value: 'pending'
      }
    ];
    const paymentStatusSelected =
      props.paymentType === INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT
        ? paymentStatus[0]
        : paymentStatus.find((item) => item.value === paymentStatusValue.value);
    const labelOptions = {
      [INVOICE_PAYMENT_TYPE.CREDIT_CARD]: 'Credit / Debit Card',
      [INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT]: 'Customer Credit',
      [INVOICE_PAYMENT_TYPE.NETS]: 'Nets'
    };
    const paymentMethod = isCreateTopUpPayment.value
      ? ref(labelOptions[topUpSummaryData.value.paymentTypeId])
      : ref(labelOptions[props.paymentType]);
    const invoicesData = props.invoices.map((invoice) => {
      return {
        label: invoice.invoiceNumber,
        value: `${props.currencySymbol}${invoice.outstandingAmount.toFixed(2) ?? 0}`
      };
    });
    const invoiceAmount = props.invoices.reduce((acc, invoice) => {
      const sum = acc + invoice.outstandingAmount ?? 0;
      return sum;
    }, 0);
    const valueDataSummary = (value) => `${props.currencySymbol}${value.toFixed(2) ?? '0.00'}`;

    const dataSummary = [
      {
        label: t('applied_credits'),
        value: valueDataSummary(props.appliedCredit)
      },
      {
        label: t('sub_total'),
        value: valueDataSummary(props.grandTotal)
      },
      {
        label: t('total_payment'),
        value: valueDataSummary(props.totalPaidAmount)
      },
      {
        label: t('payment_method'),
        value: paymentMethod.value ?? 'Debit'
      },
      {
        label: t('transaction_date'),
        value: formatDayDate(new Date(props.paymentSummary?.transactionDate))
      },
      {
        label: t('total'),
        value: paymentStatusSelected?.btmLabel || '-'
      }
    ];
    useBackButton(10, () => {
      router.push('/sale/main/home');
    });
    return {
      topUpEntryPoint,
      topUpSummaryData,
      paymentSummaryTopUp,
      isCreateTopUpPayment,
      paymentMethod,
      invoicesData,
      invoiceAmount,
      paymentStatus,
      dataSummary,
      paymentStatusSelected,
      timerOutline,
      priceFormat: (price) =>
        priceFormatter(props.currencySymbol || topUpSummaryData.value.currencySymbol, price),
      formatDayDate,
      PAYMENT_SUMMARY_STATUS_LABEL,
      PAYMENT_HISTORY_LIST_TEXT_COLOR,
      TOP_UP_CREDIT_ENTRY
    };
  }
});
</script>
<style lang="scss" scoped>
.f-icon {
  font-size: 3rem;
}
ion-content {
  --ion-background-color: #e5e5e5;
}

ion-button {
  width: 97%;
  margin: 0 auto;
  --border-radius: 10px;
}
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
  width: 94%;
  margin: 0 auto;
}
.bor-bot {
  border-bottom: 1px solid var(--ion-color-text-gray-300);
}
.bor-top {
  border-top: 1px solid var(--ion-color-text-gray-300);
}
</style>
